import {isMobileOrTablet} from "../service/helpers";
import React from "react";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {IdeasArrays} from "../components/IdeasArray";

export function FirstPage() {
  const dummyIdeas = [
    {id: 1, text: "La trilogie du Seigneur des Anneaux"},
    {id: 2, text:"OSS 117"},
    {id: 3, text:"Tente T2 pour les randonnées"},
    {id: 4, text:"Une boite de petit LU collector"},
    {id: 5, text:"Paire de chaussette Among Us"},
  ];
  return isMobileOrTablet() ? (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
      <div>
        <h3>Commencez à écrire vos idées !!!</h3>
        <Link to={'/register'}>
          <Button
            variant="contained"
            color="primary"
          >
            S'Enregistrer
          </Button>
        </Link>
      </div>
      <div>
        <h3>Retrouvez les idées de vos amis</h3>
        <Link to={'/browse-ideas'}>
          <Button
            variant="contained"
            color="primary"
          >
            Allons les Chercher
          </Button>
        </Link>
      </div>
      <div>
        <h3>Des petites idées <span role={"img"} aria-label={":)"}>😎</span></h3>
        <IdeasArrays ideas={dummyIdeas}/>
      </div>
    </div>
  ) : (
    // DESKTOP !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: 30, margin: 30}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        <div style={{justifyContent: 'center'}}>
          <h3>Commencez à écrire vos idées !!!</h3>
          <Link to={'/register'}>
            <Button
              variant="contained"
              color="primary"
            >
              S'Enregistrer
            </Button>
          </Link>
        </div>
        <div style={{justifyContent: 'center'}}>
          <h3>Retrouvez les idées de vos amis</h3>
          <Link to={'/browse-ideas'}>
            <Button
              variant="contained"
              color="primary"
            >
              Allons les Chercher
            </Button>
          </Link>
        </div>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        justifyContent: 'center', padding: 5, margin: 5, marginTop: 30,
      }}>
        <h3>Des petites idées <span role={"img"} aria-label={":)"}>😎</span></h3>
        <IdeasArrays ideas={dummyIdeas}/>
      </div>
    </div>
  );
}