import axios from "axios";

export let API_URL;
export let API_VERSION;

API_URL = "https://giftbookbackend-rorpcqlg7q-ew.a.run.app/"; // Port ?????
API_VERSION = "v1";

/* TODO
let env = process.env.NODE_ENV || 'production';
if (env !== "development") {
} else {
API_URL = 'http://localhost:5000/';
API_VERSION = "v1";
}
*/


export async function apiRegisterUser(username, password, email) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
    password: password,
    email: email,
  };

  return await axios
    .post(API_URL + API_VERSION + '/user/add', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export async function apiAdd(username, password, textIdea) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
    password: password,
    idea: textIdea,
  };

  return await axios
    .post(API_URL + API_VERSION + '/idea/add', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function apiGet(username) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
  };

  return await axios
    .post(API_URL + API_VERSION + '/idea', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function apiCheckLogin(username, password) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
    password: password,
  };

  return await axios
    .post(API_URL + API_VERSION + '/user', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export async function apiGetSearchUsers(username) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
  };

  return await axios
    .post(API_URL + API_VERSION + '/user/search', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function apiEditProfile(username, password, bio, birth, lang, country, rgpd, pic) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
    password: password,
    // DO NOT UPDATE EMAIL email": user.email,$
    bio: bio,
    rgpd: rgpd,
    birth_year: birth,
    language: lang,
    country: country,
    profile_pic: pic,
  };

  // PUT to Update user stuff
  return await axios
    .put(API_URL + API_VERSION + '/user', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function apiCommentIdea(username, password, id_idea, comment) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
    password: password,
    id_idea: id_idea,
    comment: comment,
  }
  return await axios
    .post(API_URL + API_VERSION + '/idea/select', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function apiGetUser(username, password, user_id_tolook) {
  const options = {
    header: {
      Authorization: '',
      'Content-Type': "application/json,text/plain",
      'Accept': "*/*",
    },
  };

  const data = {
    username: username,
    password: password,
    check: user_id_tolook,
  };

  return await axios
    .post(API_URL + API_VERSION + '/user', data, options)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}