import {isMobileOrTablet, showInfoOrError} from "../service/helpers";
import React, {useEffect, useState} from "react";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {apiAdd, apiGet} from "../api/api";
import {Link} from "react-router-dom";
import {IdeasArrays} from "../components/IdeasArray";

export function AddIdea(props) {
  const [infoAddIdea, setInfoAddIdea] = useState('');

  const [newIdeaText, setNewIdeaText] = useState('Un scooter ouais ouais ouais');

  // See Ideas will searching them
  const [infoGetIdeas, setInfoGetIdeas] = useState('');
  const [ideas, setIdeas] = useState([]);


  useEffect(() => {
    async function fetchMyAPI(username) {
      if (username !== '') {
        let r2 = await apiGet(username);
        if (r2 && r2.status === 200) {
          if (r2.data && r2.data.ideas && Array.isArray(r2.data.ideas)) {
            setIdeas(r2.data.ideas);
            setInfoGetIdeas('Ok!');
          } else {
            setInfoGetIdeas('Illisible, corruption des données!');
          }
        } else {
          setInfoGetIdeas('erreur!');
        }
      }
    }

    fetchMyAPI(props.username).then(_ => {
    });
  }, [props.username]);

  const updateTextNewIdea = (e) => {
    setNewIdeaText(e.target.value);
  };

  const buttonAddIdea = async () => {
    let r1 = await apiAdd(props.username, props.password, newIdeaText);
    console.log("Add Idea");
    console.log(r1);
    if (r1 && r1.status === 200) {
      setInfoAddIdea('Ok!');
      props.setConnected(true);
    } else {
      setInfoAddIdea('erreur!');
      props.setConnected(false);
    }
    let r2 = await apiGet(props.username);
    console.log("Get ideas");
    console.log(r2);
    if (r2 && r2.status === 200) {
      if (r2.data && r2.data.ideas && Array.isArray(r2.data.ideas)) {
        setIdeas(r2.data.ideas);
        setInfoGetIdeas('Ok!');
      } else {
        setInfoGetIdeas('Illisible, corruption des données!');
      }
    } else {
      setInfoGetIdeas('Erreur!');
    }
  };

  return isMobileOrTablet() ? (<div>
    {props.connected ?
      (
        <div style={{justifyContent: 'center'}}>
          <h3>Re-Bonjour {props.username}</h3>
          <h2>Nouvelle idée :</h2>
          <TextField
            id="filled-required"
            label="Idea"
            defaultValue="Un scooter ouais ouais ouais"
            style={{width: 500, minWidth: 200, maxWidth: 500}}
            onChange={updateTextNewIdea}
          />
          <h5>{' '}</h5>
          <Button
            variant="contained"
            onClick={buttonAddIdea}
            color="primary"
          >
            Ajouter
          </Button>

          <div>
            <h2>Vos idées</h2>
            <IdeasArrays ideas={ideas}
                         username={props.username}
                         password={props.password}
                         connected={props.connected}
            />
            {showInfoOrError(infoGetIdeas)}
          </div>
        </div>
      )
      :
      (
        <div>
          <h3 style={{color: 'red'}}>Attention! Vous n'êtes pas coonecté!</h3>
          <Link to={'/login'}>
            <Button
              variant="contained"
              color="primary"
            >
              Se Connecter
            </Button>
          </Link>
        </div>
      )
    }
  </div>) : (
    // DESKTOP !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    <div>
      {props.connected ?
        (
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: 20, margin: 20}}>
            <div>
              <h2>Nouvelle idée :</h2>
              <TextField
                id="filled-required"
                label="Idea"
                defaultValue="Un scooter ouais ouais ouais"
                style={{width: 500, minWidth: 200, maxWidth: 500}}
                onChange={updateTextNewIdea}
              />
              <h5>{' '}</h5>
              <Button
                variant="contained"
                onClick={buttonAddIdea}
                color="primary"
              >
                Ajouter
              </Button>
              {showInfoOrError(infoAddIdea)}
            </div>
            <div>
              <h2>Vos idées</h2>
              <IdeasArrays
                ideas={ideas}
                username={props.username}
                password={props.password}
                connected={props.connected}
              />
              {showInfoOrError(infoGetIdeas)}
            </div>
          </div>
        )
        :
        (
          <div style={{justifyContent: 'center', padding: 50, margin: 50}}>
            <h3 style={{color: 'red'}}>Attention! Vous n'êtes pas coonecté!</h3>
            <Link to={'/login'}>
              <Button
                variant="contained"
                color="primary"
              >
                Se Connecter
              </Button>
            </Link>
          </div>
        )
      }
    </div>
  )

}