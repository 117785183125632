import {isMobileOrTablet} from "../service/helpers";
import {Box, Chip, Divider, List, ListItem, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {showProfilePic} from "../helpers/helpers";

export function ProfileViewer(props) {
  // See Ideas will searching them
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [birth, setBirth] = useState('');
  const [lang, setLang] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    async function fetchMyAPI(username_id) {
      if (username_id > -1) {

      }
    }

    fetchMyAPI(props.user_id).then(_ => {
    });
  }, [props.user_id]);

  return isMobileOrTablet() ? (
    <div>
      <Box sx={{flexGrow: 1, maxWidth: 800}}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          margin: 10,
          padding: 10
        }}>
          {showProfilePic(props.pic, null)}
          <h3>{' Profil de '}{username}</h3>
        </div>
        <Divider/>
        <List>
          <ListItem>
            <div>Bio: {bio}
            </div>
          </ListItem>
          <ListItem>
            <div>Année de Naissance: {birth}
            </div>
          </ListItem>

          <ListItem>
            <div>Lang: {lang}
            </div>
          </ListItem>

          <ListItem>
            <div>Pays: {country}
            </div>
          </ListItem>

          <ListItem>
            <Box sx={{m: 2}}>
              <Typography gutterBottom variant="body1">
                Centres d'intérêt
              </Typography>
              <Box direction="row" spacing={1}>
                <Chip label="Fête"/>
                <Chip label="Voyage"/>
                <Chip label="Livres"/>
                <Chip label="Informatique"/>
                <Chip label="Histoire"/>
                <Chip label="Sport"/>
              </Box>
            </Box>
          </ListItem>
        </List>
        <Divider/>
      </Box>
    </div>
  ) : (
    // DESKTOP !!!!!!!!!!!!!!!!!!!!!!!!
    <div>
      <Box sx={{flexGrow: 1, maxWidth: 800}}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          margin: 10,
          padding: 10
        }}>
          {showProfilePic(props.pic, null)}
          <h3>{' Profil de '}{username}</h3>
        </div>
        <Divider/>
        <List>
          <ListItem>
            <div>Bio: {bio}
            </div>
          </ListItem>
          <ListItem>
            <div>Année de Naissance: {birth}
            </div>
          </ListItem>

          <ListItem>
            <div>Lang: {lang}
            </div>
          </ListItem>

          <ListItem>
            <div>Pays: {country}
            </div>
          </ListItem>

          <ListItem>
            <Box sx={{m: 2}}>
              <Typography gutterBottom variant="body1">
                Centres d'intérêt
              </Typography>
              <Box direction="row" spacing={1}>
                <Chip label="Fête"/>
                <Chip label="Voyage"/>
                <Chip label="Livres"/>
                <Chip label="Informatique"/>
                <Chip label="Histoire"/>
                <Chip label="Sport"/>
              </Box>
            </Box>
          </ListItem>
        </List>
      </Box>
    </div>
  );
};