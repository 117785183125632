import {isMobileOrTablet, showInfoOrError} from "../service/helpers";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {apiGet, apiGetSearchUsers} from "../api/api";
import {IdeasArrays} from "../components/IdeasArray";

export function BrowseIdeas(props) {
  const [infoGetIdeas, setInfoGetIdeas] = useState('');
  const [ideas, setIdeas] = useState([]);
  const [usernameLook, setUsernameLook] = useState('');
  // Users Search
  const [usernameSearch, setUsernameSearch] = useState('');
  const [resultSearch, setResultSearch] = useState([]);

  const updateUsernameLook = (e) => {
    setUsernameLook(e.target.value);
  };

  const updateUsernameSearch = async (e) => {
    setUsernameSearch(e.target.value);
    let r = await apiGetSearchUsers(e.target.value);
    console.log(r);
    if (r && r.status === 200) {
      if (r.data && r.data.users && Array.isArray(r.data.users)) {
        if (r.data.users.length !== 0 && r.data.users[0] === usernameSearch) {
          setResultSearch(r.data.users);
        }
        if (usernameSearch.length >= 3) {
          setResultSearch(r.data.users);
        }
      }

    }
  };

  const buttonGetIdeas = async () => {
    let r = await apiGet(usernameLook);
    console.log("Get ideas");
    console.log(r);
    if (r && r.status === 200) {
      if (r.data && r.data.ideas && Array.isArray(r.data.ideas)) {
        setIdeas(r.data.ideas);
        setInfoGetIdeas('Ok!');
      } else {
        setInfoGetIdeas('erreur retour incompréhensible!');
      }
    } else {
      setInfoGetIdeas('erreur!');
    }
  };

  // Mobile and Desktop Layout
  return isMobileOrTablet() ? (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 50, margin: 50}}>
      <h3>Voir les idées de quelqu'un</h3>
      <TextField
        id="filled-required"
        label="Username"
        variant="filled"
        onChange={updateUsernameLook}
      />
      <h5>{' '}</h5>
      <Button
        style={{
          maxWidth: 400
        }}
        variant="contained"
        onClick={buttonGetIdeas}
        color="primary"
      >
        Voir les Idée de {usernameLook !== '' ? usernameLook : '???'}
      </Button>
      {showInfoOrError(infoGetIdeas)}
      <IdeasArrays
        rerunIdeas={buttonGetIdeas}
        ideas={ideas}
        username={props.username}
        password={props.password}
        connected={props.connected}
        updateUserIdViewer={props.updateUserIdViewer}
      />
    </div>
  ) : (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 50, margin: 50}}>
      <h2>Voir les idées de quelqu'un</h2>
      <h4>Chercher un user :</h4>
      <TextField
        id="filled-required"
        label="Username"
        variant="filled"
        onChange={updateUsernameSearch}
      />
      <div>
        {resultSearch && Array.isArray(resultSearch) ?
          resultSearch.map((v, i) => (
            <p>{v}</p> // TODO make a link to a page with this user ideas and profile
          ))
          : null
        }
      </div>
      <h5>{' '}</h5>
      <h4>Username à regarder :</h4>
      <TextField
        id="filled-required"
        label="Username"
        variant="filled"
        onChange={updateUsernameLook}
      />
      <h5>{' '}</h5>
      <Button
        style={{
          maxWidth: 400
        }}
        variant="contained"
        onClick={buttonGetIdeas}
        color="primary"
      >
        Voir les Idée de {usernameLook !== '' ? usernameLook : '???'}
      </Button>
      {showInfoOrError(infoGetIdeas)}
      <IdeasArrays
        rerunIdeas={buttonGetIdeas}
        updateUserIdViewer={props.updateUserIdViewer}
        ideas={ideas}
        username={props.username}
        password={props.password}
        connected={props.connected}/>
    </div>
  )
}