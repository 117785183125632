import {isMobileOrTablet} from "../service/helpers";
import React from "react";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import pic1 from "../assets/pic1.png";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  Switch,
  TextField,
  Typography
} from "@material-ui/core";
import {apiEditProfile} from "../api/api";
import {showProfilePic} from "../helpers/helpers";

export function Profile(props) {
  const editProfile = async () => {
    let r1 = await apiEditProfile(
      props.username,
      props.password,
      props.bio,
      props.birth,
      props.lang,
      props.country,
      props.rgpd,
      props.pic,
    );
    console.log(r1);
  };

  return isMobileOrTablet() ? (
    <div>
      <Box sx={{flexGrow: 1, maxWidth: 800}}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          margin: 10,
          padding: 10
        }}>
          {showProfilePic(props.pic, null)}
          <h3>{' Profil de '}{props.username}</h3>
        </div>

        <List>
          <ListItem>
            <Link to={'/'}>
              <Button
                size="small" style={{color: '#2490ff'}}
                onClick={() => {
                  props.updateUsername({target: {value: ''}});
                  props.updatePassword({target: {value: ''}});
                  props.setConnected(false);
                }}
              >
                Déconnexion
              </Button>
            </Link>
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem>
            <div>Email: {props.email}</div>
          </ListItem>
          <ListItem>
            <div>Bio:
              <TextField
                multiline
                variant={"outlined"}
                rows={3}
                defaultValue={props.bio}
                onChange={props.updateBio}
              />
            </div>
          </ListItem>
          <ListItem>
            <div>Année de Naissance:
              <TextField
                defaultValue={props.birth}
                onChange={props.updateBirth}
              />
            </div>
          </ListItem>

          <ListItem>
            <div>Lang: <TextField
              defaultValue={props.lang}
              onChange={props.updateLang}
            />
            </div>
          </ListItem>

          <ListItem>
            <div>Pays:
              <TextField
                defaultValue={props.country}
                onChange={props.updateCountry}
              />
            </div>
          </ListItem>

          <ListItem>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">RGPD</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={props.rgpd} onChange={props.updateRGPD} name="rgpd"/>
                  }
                  label="RGPD - Giftbook"
                />
                <FormHelperText>Accepter le stockage et utilisation de vos données pour l'utilisation de cette
                  application</FormHelperText>
              </FormGroup>
            </FormControl>
          </ListItem>

          <ListItem>
            <Box sx={{m: 2}}>
              <Typography gutterBottom variant="body1">
                Centres d'intérêt
              </Typography>
              <Box direction="row" spacing={1}>
                <Chip label="Fête"/>
                <Chip label="Voyage"/>
                <Chip label="Livres"/>
                <Chip label="Informatique"/>
                <Chip label="Histoire"/>
                <Chip label="Sport"/>
              </Box>
            </Box>
          </ListItem>
        </List>
        <Divider/>
        <Button
          size="small" style={{color: '#2490ff'}}
          onClick={editProfile}
        >
          Confirm Edit
        </Button>

      </Box>
    </div>
  ) : (
    // DESKTOP !!!!!!!!!!!!!!!!!!!!!!!!
    <div>
      <Box sx={{flexGrow: 1, maxWidth: 800}}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          margin: 10,
          padding: 10
        }}>
          {showProfilePic(props.pic, null)}
          <h3>{' Profil de '}{props.username}</h3>
        </div>

        <List>
          <ListItem>
            <Link to={'/'}>
              <Button
                size="small" style={{color: '#2490ff'}}
                onClick={() => {
                  props.updateUsername({target: {value: ''}});
                  props.updatePassword({target: {value: ''}});
                  props.setConnected(false);
                }}
              >
                Déconnexion
              </Button>
            </Link>
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem>
            <div>Email: {props.email}</div>
          </ListItem>
          <ListItem>
            <div>Bio:
              <TextField
                multiline
                variant={"outlined"}
                rows={3}
                defaultValue={props.bio}
                onChange={props.updateBio}
              />
            </div>
          </ListItem>
          <ListItem>
            <div>Année de Naissance:
              <TextField
                defaultValue={props.birth}
                onChange={props.updateBirth}
              />
            </div>
          </ListItem>

          <ListItem>
            <div>Lang: <TextField
              defaultValue={props.lang}
              onChange={props.updateLang}
            />
            </div>
          </ListItem>

          <ListItem>
            <div>Pays:
              <TextField
                defaultValue={props.country}
                onChange={props.updateCountry}
              />
            </div>
          </ListItem>

          <ListItem>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">RGPD</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={props.rgpd} onChange={props.updateRGPD} name="rgpd"/>
                  }
                  label="RGPD - Giftbook"
                />
                <FormHelperText>Accepter le stockage et utilisation de vos données pour l'utilisation de cette
                  application</FormHelperText>
              </FormGroup>
            </FormControl>
          </ListItem>

          <ListItem>
            <Box sx={{m: 2}}>
              <Typography gutterBottom variant="body1">
                Centres d'intérêt
              </Typography>
              <Box direction="row" spacing={1}>
                <Chip label="Fête"/>
                <Chip label="Voyage"/>
                <Chip label="Livres"/>
                <Chip label="Informatique"/>
                <Chip label="Histoire"/>
                <Chip label="Sport"/>
              </Box>
            </Box>
          </ListItem>
        </List>
        <Divider/>
        <Button
          size="small" style={{color: '#2490ff'}}
          onClick={editProfile}
        >
          Confirm Edit
        </Button>

      </Box>
    </div>
  )
}