import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import {BrowseIdeas} from "./containers/BrowseIdeas";
import {Register} from "./containers/Register";
import {AddIdea} from "./containers/AddIdea";
import {apiRegisterUser} from "./api/api";
import {Login} from "./containers/Login";
import {FirstPage} from "./containers/FirstPage";
import Button from "@material-ui/core/Button";
import {Profile} from "./containers/Profile";
import {ProfileViewer} from "./containers/ProfileViewer";


export default function App() {
  const [connected, setConnected] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [bio, setBio] = useState('');
  const [birth, setBirth] = useState(0);
  const [pic, setPic] = useState(0);
  const [lang, setLang] = useState('');
  const [country, setCountry] = useState('');
  const [rgpd, setRGPD] = useState(false);

  const [user_id_viewer, setUserIdViewer] = useState(0);
  const updateUserIdViewer = (v) => {
    setUserIdViewer(v);
  };


  const updateUsername = (e) => {
    setUsername(e.target.value);
  };
  const updateEmail = (e) => {
    setEmail(e.target.value);
  };
  const updatePassword = (e) => {
    setPassword(e.target.value);
  };
  // For Updates in Profile
  const updateBio = (e) => {
    setBio(e.target.value);
  };
  const updateBirth = (e) => {
    setBirth(e.target.value);
  };
  const updatePic = (e) => {
    setPic(e.target.value);
  };
  const updateLang = (e) => {
    setLang(e.target.value);
  };
  const updateCountry = (e) => {
    setCountry(e.target.value);
  };
  const updateRGPD = (e) => {
    setRGPD(!rgpd);
  };

  const buttonAddUser = async () => {
    let r = await apiRegisterUser(username, password, email);
    console.log("Register User");
    console.log(r);
    if (r && r.status === 200) {
      setConnected(true);
    } else {
      setConnected(false);
    }
    return r;
  };

  const navLinkStyle = {
    textDecoration: "none",
    color: "#33a6ff",
  };
  return (
    <Router>
      <div>
        <div style={{borderBottom: '3px solid rgb(100,100,200)', width: '100%'}}>
          <nav
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              flexWrap: 'wrap',
            }}
          >
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center'}}>
              <Link to={'/'}>
                <Button size="small" style={{color: '#2490ff'}}>
                  Gift Book
                </Button>
              </Link>
              <p style={{
                color: '#33a6ff',
                fontSize: 13,
                marginLeft: 3
              }}>{' - La liste de cadeaux en ligne pour tous!'}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center'}}>
              <Link to={'/'} className={navLinkStyle}>
                <Button size="small" style={{color: '#2490ff'}}>
                  Accueil
                </Button>
              </Link>
              <Link to={'/browse-ideas'} className={navLinkStyle}>
                <Button size="small" style={{color: '#2490ff'}}>
                  Les
                  idées!
                </Button></Link>
              <Link to={'/idea'} className={navLinkStyle}><Button
                size="small" style={{color: '#2490ff'}}>Ajouter des
                idées!</Button></Link>
              {connected ?
                <Link to={'/profile'} className={navLinkStyle}>
                  <Button
                    size="small" style={{color: '#1976ff'}}>
                    <b>{username}</b>
                  </Button>
                </Link>
                : (
                  <>
                    <Link to={'/login'} className={navLinkStyle}>
                      <Button
                        size="small" style={{color: '#2490ff'}}>
                        Se Connecter
                      </Button>
                    </Link>
                    <Link to={'/register'}
                          className={navLinkStyle}>
                      <Button size="small"
                              style={{color: '#2490ff'}}>
                        S'Enregistrer
                      </Button>
                    </Link>
                  </>
                )
              }
            </div>
          </nav>
        </div>
        <Switch>
          <Route path='/browse-ideas'>
            <BrowseIdeas
              username={username}
              password={password}
              connected={connected}
              updateUserIdViewer={updateUserIdViewer}
            />
          </Route>
          <Route path='/idea'>
            <AddIdea
              setConnected={setConnected}
              username={username}
              password={password}
              connected={connected}
            />
          </Route>
          <Route path='/register'>
            <Register
              buttonAddUser={buttonAddUser}
              updateUsername={updateUsername}
              updateEmail={updateEmail}
              updatePassword={updatePassword}
              username={username}
              password={password}
              email={email}
              setConnected={setConnected}
            />
          </Route>
          <Route path='/login'>
            <Login
              updateUsername={updateUsername}
              updatePassword={updatePassword}
              username={username}
              password={password}
              setConnected={setConnected}
              setEmail={setEmail}
              setBio={setBio}
              setBirth={setBirth}
              setRGPD={setRGPD}
              setPic={setPic}
              setLang={setLang}
              setCountry={setCountry}
            />
          </Route>
          <Route path='/profileviewer/:id'>
            <ProfileViewer
              user_id={user_id_viewer}
            />
          </Route>
          <Route path='/profileviewer'>
            <ProfileViewer
              user_id={user_id_viewer}
            />
          </Route>
          <Route path='/profile'>
            <Profile
              updateUsername={updateUsername}
              updatePassword={updatePassword}
              username={username}
              password={password}
              setConnected={setConnected}
              email={email}
              bio={bio}
              birth={birth}
              rgpd={rgpd}
              pic={pic}
              lang={lang}
              country={country}
              updateBio={updateBio}
              updateBirth={updateBirth}
              updateRGPD={updateRGPD} // Boolean
              updatePic={updatePic}
              updateCountry={updateCountry}
              updateLang={updateLang}
            />
          </Route>
          <Route path='/'>
            <FirstPage/>
          </Route>
        </Switch>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center"}}>
          <a href={"http://sidohorizon.com"}><h6 style={{color: "#33a6ff"}}>SidoHorizon</h6></a>
        </div>
      </div>
    </Router>
  );
}
