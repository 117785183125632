import {isMobileOrTablet, showInfoOrError} from "../service/helpers";
import React, {useState} from "react";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {apiCheckLogin} from "../api/api";

export function Login(props) {
  const [infoLogin, setInfoLogin] = useState('');

  const buttonLogin = async () => {
    let r = await apiCheckLogin(props.username, props.password);
    if (r && r.status === 200) {
      setInfoLogin('Ok!');
      props.setConnected(true);
      console.log(r.data);
      if (r.data) {
        props.setEmail(r.data.email);
        props.setBio(r.data.bio);
        props.setBirth(r.data.birth_year);
        props.setRGPD(r.data.rgpd);
        props.setPic(r.data.profile_pic);
        props.setLang(r.data.language);
        props.setCountry(r.data.country);
      }
    } else {
      props.setConnected(false);
      setInfoLogin("erreur lors de la connexion de " + props.username + '!');
    }
  };

  return isMobileOrTablet() ? (
    <div>
      <h2>Se connecter à GiftBook <span role={"img"} aria-label={""}>🎁📖</span></h2>
      <h4>Username :</h4>
      <TextField
        id="filled-required"
        label="Username"
        variant="filled"
        defaultValue={props.username}
        onChange={props.updateUsername}
      />
      <h4>Password :</h4>
      <TextField
        id="filled-required"
        label="Password"
        type="password"
        variant="filled"
        defaultValue={props.password}
        onChange={props.updatePassword}
      />
      <h5>{' '}</h5>
      <Button
        variant="contained"
        onClick={buttonLogin}
        color="primary"
      >
        Se connecter
      </Button>
    </div>
  ) : (
    // DESKTOP !!!!!!!!!!!!!!!!!!!!!!!!
    <div>
      <div style={{justifyContent: 'center', padding: 50, margin: 50}}>
        <h2>Se connecter à GiftBook <span role={"img"} aria-label={""}>🎁📖</span></h2>
        <h4>Username :</h4>
        <TextField
          id="filled-required"
          label="Username"
          variant="filled"
          defaultValue={props.username}
          onChange={props.updateUsername}
        />
        <h4>Password :</h4>
        <TextField
          id="filled-required"
          label="Password"
          type="password"
          variant="filled"
          defaultValue={props.password}
          onChange={props.updatePassword}
        />
        <h5>{' '}</h5>
        <Button
          variant="contained"
          onClick={buttonLogin}
          color="primary"
        >
          Se connecter
        </Button>
      </div>
      {showInfoOrError(infoLogin)}
    </div>

  )

}