import {isMobileOrTablet, showInfoOrError} from "../service/helpers";
import React, {useState} from "react";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export function Register(props) {
  const [infoRegister, setInfoRegister] = useState('');

  const buttonAddUserLocal = async () => {
    let r = await props.buttonAddUser();
    if (r && r.status === 200) {
      setInfoRegister(props.username + ' ajouté!');
      props.setConnected(true);
    } else {
      props.setConnected(false);
      setInfoRegister("erreur lors de l'ajout de " + props.username + '!');
    }
  };

  return isMobileOrTablet() ? (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
      <h2>Création de votre Compte GiftBook <span role={"img"} aria-label={""}>🎁📖</span></h2>
      <div>
        <h4>Username :</h4>
        <TextField
          id="filled-required"
          label="Username"
          variant="filled"
          defaultValue={props.username}
          onChange={props.updateUsername}
        />
        <h4>Email :</h4>
        <TextField
          id="filled-required"
          label="Email"
          variant="filled"
          defaultValue={props.email}
          onChange={props.updateEmail}
        />
        <h4>Password :</h4>
        <TextField
          id="filled-required"
          label="Password"
          type="password"
          variant="filled"
          defaultValue={props.password}
          onChange={props.updatePassword}
        />
      </div>
      <h5>{' '}</h5>
      <Button
        variant="contained"
        onClick={buttonAddUserLocal}
        color="primary"
      >
        Créer votre Compte
      </Button>
    </div>
  ) : (
    // DESKTOP !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: 50, margin: 50}}>
      <h2>Création de votre Compte GiftBook <span role={"img"} aria-label={""}>🎁📖</span></h2>
      <div>
        <h4>Username :</h4>
        <TextField
          id="filled-required"
          label="Username"
          variant="filled"
          defaultValue={props.username}
          onChange={props.updateUsername}
        />
        <h4>Email :</h4>
        <TextField
          id="filled-required"
          label="Email"
          variant="filled"
          defaultValue={props.email}
          onChange={props.updateEmail}
        />
        <h4>Password :</h4>
        <TextField
          id="filled-required"
          label="Password"
          type="password"
          variant="filled"
          defaultValue={props.password}
          onChange={props.updatePassword}
        />
      </div>
      <h5>{' '}</h5>
      <Button
        variant="contained"
        onClick={buttonAddUserLocal}
        color="primary"
      >
        Créer votre Compte
      </Button>
      {showInfoOrError(infoRegister)}
    </div>
  );
}