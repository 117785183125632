import React from "react";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import {CommentIdea} from "./CommentIdea";
import {UserName} from "./UserName";

let userColorsCommentName = [
  '#794cd2',
  '#4887c9',
  '#d2b74c',
  '#c95748',
  '#d24c50',
  '#64c948',
  '#bc48c9',
  '#484ec9',
]

export function IdeasArrays(props) {
  const userIdToColor = (id, user_str) => {
    let color = userColorsCommentName[id % userColorsCommentName.length];
    return (<span style={{color: color, fontWeight: 'bold'}}>{user_str}</span>);
  };
  let ideas = props.ideas;
  if (!ideas || !Array.isArray(ideas) || ideas.length === 0) {
    return <p>{"Pas d'idées 🤷‍♂️"}</p>;
  }
  let showIdeas = [];
  if (ideas && Array.isArray(ideas) && ideas.length > 0) {
    for (let i in ideas) {
      let idea_i = ideas[i];
      let has = false;
      for (let j in showIdeas) {
        let idea_j = showIdeas[j];
        if (idea_j.id === idea_i.id) {
          has = true;
          break;
        }
      }
      if (!has) {
        let selected_by = [];
        if (idea_i.selected_id) {
          selected_by.push({
            user_id: idea_i.selected_id,
            comment: idea_i.comment,
          });
        }
        showIdeas.push({
          id: idea_i.id,
          text: idea_i.text,
          selected_by: selected_by,
        })
      } else {
        for (let j in showIdeas) {
          let idea_j = showIdeas[j];
          // I f already Idea
          if (idea_j.id === idea_i.id) {
            if (idea_i.selected_id) {
              idea_j.selected_by.push({
                user_id: idea_i.selected_id,
                comment: idea_i.comment,
              });
            }
          }
        }
      }
    }
  }
  console.log(showIdeas);
  return (
    <div>
      {ideas && Array.isArray(ideas) && ideas.length > 0 ?
        showIdeas.map((v, i) => (
          <div
            key={i.toString() + v.text}
            style={{
              borderRadius: 30,
              width: 550,
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 7,
              paddingBottom: 7,
              margin: 5,
              background: '#EFEFEFAA'
            }}>
            <Box direction="row" spacing={1}>
              <Button
                size="small" style={{color: '#2490ff'}}
                onClick={() => {
                }}
              >
                Se charger de ce cadeau
              </Button>
            </Box>
            <p style={{width: 450}}><span style={{color: '#2490ff'}}>{'| '}</span>{v.text}</p>
            {v.selected_by.map((vv, ii) => (
              <p key={"comments" + ii}>
                <UserName
                  user_id={vv.user_id}
                  updateUserIdViewer={props.updateUserIdViewer}>
                  {userIdToColor(vv.user_id, vv.user_id)}
                </UserName>
                {' '}
                {vv.comment}
              </p>
            ))}
            {props.connected ?
              <CommentIdea
                username={props.username}
                password={props.password}
                id_idea={v.id}
                rerunIdeas={props.rerunIdeas}
              />
              : null}
          </div>
        ))
        : <p>{'Empty'}</p>
      }
    </div>
  );
}