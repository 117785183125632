import {Box, TextField, Typography} from "@material-ui/core";
import React, {useRef, useState} from "react";
import {apiCommentIdea} from "../api/api";

export function CommentIdea(props) {
  const [comment, setComment] = useState('');
  let textInput = useRef(null);
  return (
    <Box>
      <Typography>Commenter:</Typography>
      <TextField
        onKeyPress={async (e) => {
          if (e.key === "Enter" && comment !== "") {
            e.preventDefault();
            // Send to API
            console.log(comment);
            let r = await apiCommentIdea(props.username, props.password, props.id_idea, comment);
            console.log(r);
            setComment("");
            textInput.current.value = "";
            if (props.rerunIdeas) {
              props.rerunIdeas();
            }
          }
        }}
        inputRef={textInput}
        variant={"outlined"}
        onChange={(e) => setComment(e.target.value)}
      />
    </Box>
  )
}